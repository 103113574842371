import React from "react"
import { Helmet } from "react-helmet"
import "react-responsive-modal/styles.css"
import Layout from "../../components/layout"
import Shapefour from "../../images/bannerElement/white-design-element-career.svg"
import Shapeone from "../../images/bannerElement/white-design-element-one.svg"
import Shapethree from "../../images/bannerElement/white-design-element-three.svg"
import Shapetwo from "../../images/bannerElement/white-design-element-two.svg"
import "../../styles/careers.css"

export default function MarketingManager() {
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Account Manager Performance Marketing | Position²</title>
        <script src="https://use.fortawesome.com/23bb216b.js"></script>
        <body className="home" />
      </Helmet>
      <Layout>
        <div class="careerstyle marketing-manager">
          <section id="Banner">
            <div className="container">
              <div className="banner-holder">
                <div className="images">
                  <div className="img-one image-shape">
                    <img src={Shapeone} alt="Image" />
                  </div>
                  <div className="img-two image-shape">
                    <img src={Shapetwo} alt="Image" />
                  </div>
                  <div className="img-three image-shape">
                    <img src={Shapethree} alt="Image" />
                  </div>
                  <div className="img-four image-shape">
                    <img src={Shapefour} alt="Image" />
                  </div>
                </div>
                <div className="title">
                  <div className="banner-title">
                    <h1>Careers</h1>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="PageIntro">
            <div class="container">
              <h2 className="career-heading">Senior Graphic Designer</h2>
              <br />
              <h4>Location</h4>
              <p className="career-para">Bangalore, India</p> <br />
              <h4>Job Summary</h4>
              <p className="career-para">
                We seek a Senior Designer to produce and oversee digital
                creative assets to address our marketing needs. To be successful
                in this role, you should have in-depth knowledge of graphic
                design, styles, and layout techniques. It would be best if you
                also had experience executing marketing projects from conception
                to production, including Digital Banner Ads, PPT Presentations,
                Brochures, Product Flyers, Social post images, and Infographics.
                Ultimately, you will ensure the design team promotes our brand
                by delivering high-quality pieces on tight deadlines.
              </p>
              <br />
              <h4>Job Description</h4>
              <ul className="bullet-point">
                <li>
                  Oversee all design projects, from conception to delivery
                </li>
                <li>
                  Design original pieces, including illustrations and
                  infographics
                </li>
                <li>Review junior designers’ work to ensure high-quality</li>
                <li>
                  Refine images, fonts, and layouts using graphic design
                  software
                </li>
                <li>Apply typography techniques</li>
                <li>
                  Generate ideas to portray concepts and advertise
                  products/services
                </li>
                <li>Increase user friendliness in digital products</li>
                <li>
                  Maintain brand consistency throughout all our marketing
                  projects
                </li>
                <li>
                  Liaise with marketing and design teams to ensure deadlines are
                  met
                </li>
                <li>Stay up-to-date with industry developments and tools</li>
              </ul>
              <h4>Required Skills</h4>
              <ul className="bullet-point">
                <li>
                  Proven work experience as a Senior Designer, Graphic Designer,
                  or similar role
                </li>
                <li>Portfolio of completed design projects</li>
                <li>
                  Hands-on experience with image editing software, like
                  Photoshop and Adobe Illustrator
                </li>
                <li>
                  Proficient in Adobe Creative Suite Strong aesthetic skills
                  with the ability to combine various colors, fonts, and layouts
                </li>
                <li>Attention to visual details</li>
                <li>Ability to meet deadlines and collaborate with a team</li>
              </ul>
              <div class="cta">
                <a href="mailto:careers@position2.com" class="apply-cta">
                  Apply
                </a>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  )
}
